import React from 'react'
import { useState } from 'react'

const Narrative = () => {
    const [liked, setLiked] = useState(false)
    function like(){
        setLiked(!liked)
      }
  return (
    <section className='comp'>
    <main className='top'>
      <div className='profile'>
      <img alt='' src='/images/log.jpg' />
      </div>
      <div>
      <h1>
        Narrative  
      </h1>
      <small>Worldwide</small>
      </div>
    </main>
    <article className='bottom'>
    <main className='imagesSLides' id='narr' style={{backgroundColor: 'black', color: 'white', display: 'flex', flexDirection:'column', justifyContent:'center', lineHeight:'30px', textAlign:'center', alignItems:'center', fontSize: '14px'}}>
        <h2>
        Divorced Dad is the worlds first ever cryptocurrency support token specifically for divorced dads. We have all been there, these bitches just don’t understand. Divorced Dads will unite and spite send into the billions and our ex-wives aren’t getting a penny of it. 
        </h2>
        <button><a href='https://dexscreener.com/solana/dtpr6zzmucsnpptufahqe2qj7aera3c2lfvdxphvktfe'>Chart</a></button>
    </main>
      <div>
        <span className='reactions'>
        <img alt="" src= {liked ? "/images/loved.png" : "/images/icons8-love-50.png"}  onClick={like}  />
          <img alt='' src='/images/icons8-comment-50.png'/>
          <img alt='' src='/images/icons8-send-50.png'/>
        </span>
      </div>
      <small>
        <img src='' alt='' />
        Liked by <b>Boden</b> and <b>84,280 others</b>
      </small>
      <p>
        <b>Narrative</b> We've all been through heartbreak one way or the other ,let's support each here
      </p>
    </article>
  </section>
  )
}

export default Narrative