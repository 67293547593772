import React from 'react'
import './Nav.css'

const Nav = () => {
  return (
        <div className='navStory'>
        <main>
        <img alt='' src='/images/div1.jpg' />
        <p>Dad</p>
        </main>
        <main>
        <img alt='' src='/images/st1.jpg' />
        <p>Wojak</p>
        </main>
        <main>
        <img alt='' src='/images/st2.jpg' />
        <p>Dad</p>
        </main>
        <main>
        <img alt='' src='/images/st3.jpg' />
        <p>Will</p>
        </main>
        <main>
        <img alt='' src='/images/st4.jpg' />
        <p>Johny</p>
        </main>
{/*               
              <img alt='' src='/images/st2.jpg' />
              <img alt='' src='/images/st3.jpg' />
              <img alt='' src='/images/st4.jpg' />
              <img alt='' src='/images/st1.jpg' /> */}
            </div>
  )
}

export default Nav