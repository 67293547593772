import './App.css';
import Footer from './components/Footer/Footer';
import Message from './components/Message/Message';
import Middle from './components/Middle/Middle';
import Narrative from './components/Narrative/Narrative';
import Nav from './components/Nav/Nav';
import Tokenomics from './components/Tokenomics/Tokenomics';
import Top from './components/Top/Top';
import Divorceddad from './components/divorcedComp/divorceddad';

function App() {
  return (
    <div className='app'>
    <Top/>
        <Nav/>
        <Divorceddad/>
        <Middle/>
        <Narrative/>
        <Tokenomics/>
        <Message/>
        <Footer/>
    </div>
  );
}

export default App;
