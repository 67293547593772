import React from 'react'
import './Top.css'

const Top = () => {
  return (
    <div className='head'>
    <section>
        <h1>Instagram</h1>
        <span>
            <img alt='love' src='/images/icons8-love-50.png' />
            <img alt='love' src='/images/message.png' />
        </span>
    </section>
        
    </div>
  )
}

export default Top