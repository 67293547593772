import React from 'react'
import { useEffect, useRef, useState } from 'react';

const Message = () => {
    const [liked, setLiked] = useState(false)
    const videoRef = useRef(null);

    useEffect(() => {
        const options = {
          root: null, // Use the viewport as the root
          threshold: 0.5, // Play when 50% of the video is visible
        };
    
        const handleIntersection = (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // User has interacted by scrolling video into view
              videoRef.current.play().catch((error) => {
                // Handle potential play() errors gracefully (e.g., display a message)
                console.error('Error playing video:', error);
              });
            } else {
              videoRef.current.pause();
            }
          });
        };
    
        const observer = new IntersectionObserver(handleIntersection, options);
        observer.observe(videoRef.current);
    
        return () => {
          observer.disconnect();
        };
      }, []);
    
    function like(){
        setLiked(!liked)
      }

  return (
    <section className="comp">
    <main className="top">
      <div className="profile">
        <img alt="" src="/images/log.jpg" />
      </div>
      <div>
        <h1>divorceddadsol</h1>
        <small>New York</small>
      </div>
    </main>
    <article className="bottom">
    <div className="vids">
    <div>
    <video ref={videoRef} controls loop>
          <source src="/videos/vid1.MOV" type="video/mp4" />
        </video>  
    </div>
    </div>
      <div>
        <span className="reactions">
        <img alt="" src= {liked ? "/images/loved.png" : "/images/icons8-love-50.png"}  onClick={like}  />
          <img alt="" src="/images/icons8-comment-50.png" />
          <img alt="" src="/images/icons8-send-50.png" />
        </span>
      </div>
      <small>
        <img src="" alt="" />
        Liked by <b>Elon musk</b> and <b>1,144,700 others</b>
      </small>
      <p>
        <b>divorceddadsol</b> To all the divorced dads worldwide 
      </p>
    </article>
  </section>
  )
}

export default Message