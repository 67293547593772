import React from "react";
import './Footer.css'

const Footer = () => {
  return (
    <section className="comp">
  
      <div className="socials">
          <h1>Divorced Dad support </h1>
          <span className="reactions">
            <a href="https://twitter.com/DivorcedDadSol">
              <img alt="" src="/images/twitter.png" />
            </a>
            <a href="https://t.me/DivorcedDadTakeover">
              <img alt="" src="/images/telegram.png" />
            </a>
          </span>
        </div>
    </section>
  );
};

export default Footer;
