import React from 'react'
import { useState } from 'react'

const Middle = () => {
    const [liked, setLiked] = useState(false)
    function like(){
        setLiked(!liked)
      }
  return (
    <section className="comp">
    <main className="top">
      <div className="profile">
        <img alt="" src="/images/log.jpg" />
      </div>
      <div>
        <h1>divorceddadsol</h1>
        <small></small>
      </div>
    </main>
    <article className="bottom">    
        <div className="imagesSLides">
        <img alt="" src="/images/div1.jpg" className="mainimg" />
        <img alt="" src="/images/div2.jpg" className="mainimg" />
        <img alt="" src="/images/div3.jpg" className="mainimg" />
        <img alt="" src="/images/div4.jpg" className="mainimg" />
      </div>
      <div>
        <span className="reactions">
        <img alt="" src= {liked ? "/images/loved.png" : "/images/icons8-love-50.png"}  onClick={like}  />
          <img alt="" src="/images/icons8-comment-50.png" />
          <img alt="" src="/images/icons8-send-50.png" />
        </span>
      </div>
      <small>
        <img src="" alt="" />
        Liked by <b>Vibing Cat</b> and <b>64,700 others</b>
      </small>
      <p>
        <b>divorceddadsol</b> From Breakup to Breakthrough: The Divorced Dads Hustle Hub! Join forces with like-minded fathers, turning challanges to opportunities
      </p>
    </article>
  </section>
  )
}

export default Middle