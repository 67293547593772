import React from "react";
import "./divorced.css";
import { useEffect, useRef, useState } from "react";
const Divorceddad = () => {
  const [liked, setLiked] = useState(false)
  const videoRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      threshold: 0.5, // Play when 50% of the video is visible
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // User has interacted by scrolling video into view
          videoRef.current.play().catch((error) => {
            // Handle potential play() errors gracefully (e.g., display a message)
            console.error('Error playing video:', error);
          });
        } else {
          videoRef.current.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    observer.observe(videoRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);


  function like(){
    setLiked(!liked)
  }
  return (
    <section className="comp">
      <main className="top">
        <div className="profile">
          <img alt="" src="/images/log.jpg" />
        </div>
        <div>
          <h1>Divorced Dads</h1>
          <small>Albemarle</small>
        </div>
      </main>
      <article className="bottom">
      <div className="vids">
      <div>
      <video ref={videoRef} controls loop>
            <source src="/videos/vid2.MP4" type="video/mp4" />
          </video>  
      </div>
      </div>
        <div>
          <span className="reactions">
            <img alt="" src= {liked ? "/images/loved.png" : "/images/icons8-love-50.png"}  onClick={like}  />
            <img alt="" src="/images/icons8-comment-50.png" />
            <img alt="" src="/images/icons8-send-50.png" />
          </span>
        </div>
        <small>
          <img src="" alt="" />
          Liked by <b>pepe</b> and <b>{44},{liked ? 700 + 1 : 700} others</b>
        </small>
        <p>
          <b>Divorced Dads</b> POV your wife left you 2years ago 
        </p>
      </article>
    </section>
  );
};

export default Divorceddad;
